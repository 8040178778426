var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-card', [_c('b-row', [_c('b-col', {
    staticClass: "d-flex-center justify-content-start gap-3 mb-1",
    attrs: {
      "cols": "12"
    }
  }, [_c('label', {
    staticClass: "mb-0",
    attrs: {
      "for": "enable-payment-password"
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.paymentPassword.title')) + " ")]), _c('b-form-checkbox', {
    staticClass: "custom-control-success",
    attrs: {
      "id": "enable-payment-password",
      "checked": _vm.enablePaymentPassword,
      "name": "check-button",
      "switch": "",
      "inline": ""
    },
    nativeOn: {
      "click": function click($event) {
        $event.preventDefault();
        return _vm.handleInputEnablePaymentPassword($event);
      }
    }
  })], 1)], 1), _c('b-row', [_c('b-col', {
    staticClass: "d-flex-between",
    attrs: {
      "cols": "12"
    }
  }, [_vm.enablePaymentPassword ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(255, 255, 255, 0.15)',
      expression: "'rgba(255, 255, 255, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": _vm.changePaymentPassword
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.paymentPassword.changePassword')) + " ")]) : _vm._e(), _vm.canResetPaymentPassword ? _c('b-button', {
    directives: [{
      name: "ripple",
      rawName: "v-ripple.400",
      value: 'rgba(186, 191, 199, 0.15)',
      expression: "'rgba(186, 191, 199, 0.15)'",
      modifiers: {
        "400": true
      }
    }],
    attrs: {
      "variant": "outline-secondary"
    },
    on: {
      "click": _vm.resetPaymentPassword
    }
  }, [_vm._v(" " + _vm._s(_vm.$t('myAccount.paymentPassword.reset')) + " ")]) : _vm._e()], 1)], 1)], 1);
}
var staticRenderFns = []

export { render, staticRenderFns }